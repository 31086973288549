import { nowTime } from '../utils'

export default function NotFound() {
  return (
    <div>
      <h1>Oops!</h1>
      <span>{nowTime()}</span>
    </div>
  );
}
